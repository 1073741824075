<template>
  <div>
    <form v-if="form" class="mt-5" @submit.prevent="submit">
      <CustomInput v-model="form.name" :label="$t('name')"></CustomInput>

      <CustomInput
        v-model="form.emailAddress"
        :label="$t('email')"
        :type="'email'"
      ></CustomInput>

      <CustomInput
        v-model="form.phoneNumber"
        :label="$t('phone')"
        :type="'phone'"
      ></CustomInput>

      <b-field :label="$tc('language', 1)" label-for="language" horizontal>
        <b-select id="language" v-model="form.language" required>
          <option v-for="l in languages" :key="l.id" :value="l['@id']">
            {{ l.isoName }}
          </option>
        </b-select>
      </b-field>

      <b-field :label="$tc('role')" label-for="role2" horizontal>
        <b-select id="role2" v-model="form.role" required>
          <option
            v-for="r in userRoles"
            :key="r.role"
            :value="r.role"
            :disabled="r.disabled"
          >
            {{ $t(r.role) }}
            {{ r.disabled ? ` (${$t("limit_reached")})` : "" }}
          </option>
        </b-select>
      </b-field>

      <b-button
        type="is-primary"
        native-type="submit"
        icon-left="content-save"
        :loading="loading"
      >
        {{ $t("save") }}
      </b-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CustomInput from "../../../components/forms/CustomInput.vue";

export default {
  components: { CustomInput },
  props: {
    mode: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      userRoles: [{ role: "ROLE_USER" }, { role: "ROLE_SUPERADMIN" }],
      form: {
        name: this.user?.name,
        emailAddress: this.user?.emailAddress,
        phoneNumber: this.user?.phoneNumber,
        language: this.user?.language["@id"],
        role: null,
      },
    };
  },
  computed: {
    ...mapGetters(["findLanguageByISOCode", "userHasRoleSuperAdmin"]),
    ...mapState({
      entity: (state) => state.auth.entity,
      languages: (state) => state.global.languages,
    }),
  },
  async mounted() {
    const companyUsers = await this.getAllCompanyUsers(this.entity.id);

    // Only allow company admin role when limit (2) not reached
    if (!this.userHasRoleSuperAdmin) {
      const admins = companyUsers.filter(
        (cu) => !cu.blocked && cu.roles.includes("ROLE_COMPANY_ADMIN")
      ).length;
      if (admins < 2) {
        this.companyUserRoles.find(
          (r) => r.role === "ROLE_COMPANY_ADMIN"
        ).disabled = false;
      }
    }

    if (this.mode === "create") {
      this.form.language = this.findLanguageByISOCode("en")["@id"];
      this.form.role = "ROLE_USER";
    }

    if (this.mode === "edit") {
      this.form.role = this.user.roles.includes("ROLE_SUPERADMIN")
        ? "ROLE_SUPERADMIN"
        : "ROLE_USER";
    }
  },
  methods: {
    ...mapActions(["getAllCompanyUsers"]),
    submit() {
      this.$emit("submit", {
        ...this.form,
        roles: [this.form.role],
        companyUserRole: ["ROLE_COMPANY_USER"],
      });
    },
  },
};
</script>
