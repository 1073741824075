<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>
          {{ $tc("user", 2) }} &ndash; {{ user.emailAddress }} &ndash;
        </span>
        {{ $t("edit") }}
      </h1>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton
          :parent-route="{ name: 'UserView', params: { id } }"
        ></BackButton>

        <UserForm :mode="'edit'" :user="user" @submit="submit"></UserForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../components/BackButton";
import UserForm from "./partials/UserForm";

export default {
  name: "UserEdit",
  components: { BackButton, UserForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      user: null,
    };
  },
  computed: {
    ...mapGetters(["userHasRoleSuperAdmin"]),
  },
  mounted() {
    this.getUser(this.id).then((user) => {
      this.user = user;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getUser", "updateCompanyUser", "updateUser"]),
    submit(payload) {
      this.loading = true;

      this.updateUser({
        id: this.user.id,
        user: payload,
      })
        .then(async (user) => {
          if (!this.userHasRoleSuperAdmin) {
            const companyUser = user.companies.find(
              (cu) => cu.userAccount === user["@id"]
            );
            await this.updateCompanyUser({
              id: companyUser.id,
              companyUser: {
                ...companyUser,
                roles: payload.companyUserRole,
              },
            });
          }

          this.$buefy.toast.open({
            message: this.$t("user_updated"),
            type: "is-primary",
          });

          this.$router.push({
            name: "UserView",
            params: { id: this.user.id },
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
